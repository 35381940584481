<style>
	.error-body {
		color: #fff;
		text-align: center;
		padding-top: 10%;
	}
	h1 {
		font-size: 50px;
		font-weight: bold;
		letter-spacing: 3px;
	}
	.bg-close {
		height: 170px;
	}
	a, a:hover {
		color: #fff;
		font-weight: bold;
		font-size: 25px;
		letter-spacing: 1.7px;
	}
</style>

<template>
	<div>
		<div class="container">
	        <div class="error-body">
	            <h1>{{ site_name.toUpperCase() }}</h1>
	            <p>
	                Hệ thống đã chuyển địa chỉ và tên miền về tại <br> <a :href="'//' + owner_data.site_name" class="text-uppercase">{{ owner_data.site_name }}</a> <br> Truy cập để sử dụng nhiều dịch vụ hơn!<hr />
	                Đang chuyển hướng về hệ thống <b class="text-uppercase">{{ owner_data.site_name }}</b> sau {{ second }}s...
	            </p>
	            <img :src="require('@/assets/images/close_site.png')" class="bg-close" alt="">
	        </div>
		</div>
	</div>
</template>

<script>
	export default {
		data () {
			return {
				site_name: window.location.hostname,
				second: 10
			}
		},
		computed: {
			owner_data () {
				return this.$store.state.owner_data;
			},
		},
		created () {
			this.$store.dispatch('siteData').then((data) => {
                if (data.go_to != 'close') {
                	this.$router.push('/').catch(err => {});
                } else {
                	let vm = this;
					let itv = setInterval(function () {
						vm.second--;
						if (vm.second <= 0) {
							window.location = '//' + vm.owner_data.site_name;
							clearInterval(itv);
						}
					}, 1000);
					jQuery(($) => {
						$('body').addClass('bg-main');
					});
                }
            });
		}
	}
</script>